import type { GetUserResponse } from '@videoblocks/account-service-client';

import { SessionApi, UserApi } from 'account-service-client';

import { PageViewEventFactory } from './PageViewEventFactory';
import KafkaRestClient from '../KafkaRestClient';
import { type ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';
import {
    type Geo,
} from '@videoblocks/events-ts/lib/storyblocks/navigation/PageViewEvent';

async function publishEvent(
    currentUrl: URL,
    cookies: ReadonlyRequestCookies,
    headers: Headers,
    statusCode: number,
    referrer: URL | undefined,
    geo: Geo | null
): Promise<void> {
    const visitorIdCookie = cookies.get('VID')?.value ?? null;
    const loginSessionToken = cookies.get('login_session')?.value;
    const searchIdCookie = cookies.get('SSLID')?.value ?? null;
    const searchPageIdCookie = cookies.get('SGUID')?.value ?? null;

    const getUserResponse = await lookupUser(loginSessionToken);

    const event = PageViewEventFactory.create({
        headers,
        currentUrl: currentUrl,
        visitorCookieId: visitorIdCookie,
        getUserResponse,
        statusCode,
        searchPageIdCookie,
        searchIdCookie,
        referrer,
        geo
    });

    await KafkaRestClient.produce(event);
}

export const PageViewService = { publishEvent };

async function lookupUser(sessionToken: string | undefined): Promise<GetUserResponse | null> {
    if (!sessionToken) {
        return null;
    }

    const session = await SessionApi.getBySessionToken(sessionToken);

    if (!session || !session.userId) {
        return null;
    }

    return UserApi.getByUserId(session.userId);
}
