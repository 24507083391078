import { incrementTelemetry } from 'core-events';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';

import { SearchFilterContentTypes, getTrendingTagLinks, SearchOption } from '@videoblocks/shared-components';
import { Button } from '@videoblocks/storywind';

import { CallToActionProps } from '../elements/CallToAction.builder';
import ProminentSearchContainer from '../elements/ProminentSearchContainer';
import getAssetSource from '../utils/getAssetSource';

/**
 * This component also exists in Stockblocks. The two should be kept in sync
 * until it goes away in Stockblocks.
 */

const imagesUrl = process.env.NEXT_PUBLIC_ASSET_PATH ?? '/images';

const eventTrackingForProminentSearch = () => {
    incrementTelemetry('homepagesearch.trending_keyword_click');
};

export const defaultContentTypes: SearchOption[] = [
    {
        id: SearchFilterContentTypes.All_videos_content_type,
        label: 'Video',
        placeholderText: 'Search video library...',
    },
    {
        id: SearchFilterContentTypes.Templates,
        label: 'Video Templates',
        placeholderText: 'Search templates library...',
    },
    {
        id: SearchFilterContentTypes.All_audio_content_type,
        label: 'Audio',
        placeholderText: 'Search audio library...',
    },
    {
        id: SearchFilterContentTypes.All_images_content_type,
        label: 'Images',
        placeholderText: 'Search image library...',
    },
];

interface Props {
    headingFirstLine?: string;
    headingSecondLine?: string;
    subheading?: string;
    videoSrc: string;
    posterSrc: string;
    trendingTags?: string;
    contentTypes?: SearchOption[];
    tall?: boolean;
    alignment?: 'left' | 'center';
    showCallToAction: boolean;
    callToAction?: CallToActionProps;
    showSecondaryCta: boolean;
    secondaryCta?: CallToActionProps;
}

function SearchHero({
    headingFirstLine = 'Get Unlimited',
    headingSecondLine = 'Stock Media',
    subheading,
    trendingTags = 'Documentary,Love,Sport',
    videoSrc = '/home/video-homepage-hero-background.webm',
    posterSrc = '/home/video-homepage-hero-background.jpg',
    contentTypes = defaultContentTypes,
    tall,
    alignment,
    showCallToAction,
    callToAction,
    showSecondaryCta,
    secondaryCta,
}: Props) {
    const [contentType, setContentType] = useState(SearchFilterContentTypes.All_videos_content_type);
    const hasAnyCtaButton = (showCallToAction && callToAction?.href) || (showSecondaryCta && secondaryCta?.href);

    return (
        <div className={twMerge('relative', tall ? 'min-h-100 md:min-h-160' : 'min-h-60 md:min-h-100')}>
            {/*Background*/}
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden bg-gray-900">
                <div className="overflow-hidden w-full h-full">
                    <div className="transition-all h-full whitespace-nowrap">
                        {!isMobile && (
                            <video
                                className="hero-video w-full h-full object-cover"
                                poster={getAssetSource(posterSrc)}
                                loop
                                autoPlay
                                muted
                            >
                                <source src={getAssetSource(videoSrc)} type="video/mp4" />
                            </video>
                        )}
                    </div>
                </div>
            </div>
            {/*Header Container*/}
            <div
                className={twMerge(
                    'flex w-full relative z-10 m-auto',
                    alignment === 'left' ? 'justify-left' : 'justify-center',
                )}
            >
                <div
                    className={twMerge(
                        'gap-y-16 max-w-screen-2xl grid grid-cols-12 px-4 md:px-12 lg:px-24 pt-8',
                        tall ? 'md:pt-36 lg:pt-36' : 'md:pt-20 lg:pt-20',
                        hasAnyCtaButton && 'md:pt-12 md:pb-12',
                    )}
                >
                    {/*Header*/}
                    <div className="justify-center flex flex-col col-span-full px-2 md:px-4 lg:px-4">
                        {/*Header Text*/}
                        <div className="justify-center flex flex-col pb-3 md:pb-6 lg:pb-6">
                            <h1
                                className={twMerge(
                                    'font-polysans font-bold text-white mb-0',
                                    tall ? 'text-4xl ppro-sm:text-5xl md:text-7xl lg:text-7xl' : 'text-3xl',
                                    alignment === 'center' && 'text-center',
                                )}
                            >
                                {headingFirstLine}
                                <br />
                                {headingSecondLine}
                            </h1>
                            {subheading && (
                                <span
                                    className={twMerge(
                                        'font-bold text-white font-polysans',
                                        tall ? 'mt-4 text-2xl' : 'mt-2',
                                        alignment === 'center' && 'text-center',
                                    )}
                                >
                                    {subheading}
                                </span>
                            )}
                        </div>
                        <div id="unifiedNav-search-app" className="prominent-search-bar">
                            <ProminentSearchContainer
                                imagesUrl={imagesUrl}
                                contentTypes={contentTypes}
                                contentType={contentType}
                                setContentType={setContentType}
                                showDropdown={false}
                                alignment={alignment}
                            />
                        </div>
                        <div
                            className={twMerge(
                                'flex',
                                tall && 'pt-6 pb-8 md:pb-24 lg:pb-24',
                                hasAnyCtaButton && 'md:pb-6 lg:pb-6',
                                alignment === 'center' && 'justify-center',
                            )}
                        >
                            <p className="pr-1 text-white text-sm mobile-md:text-base ppro-sm:text-base md:text-base lg-text-base">
                                Trending:
                            </p>
                            {trendingTags.split(',').map((tag, idx) => (
                                <a
                                    className="homepage-trending text-white text-sm mobile-md:text-base ppro-sm:text-base md:text-base lg-text-base font-bold underline pr-1 offset-5"
                                    href={getTrendingTagLinks(tag.toLowerCase().replace(/\s/g, '-'), contentType)}
                                    onClick={eventTrackingForProminentSearch}
                                    key={tag}
                                >
                                    {tag}
                                    {idx === trendingTags.split(',').length - 1 ? '' : ','}
                                </a>
                            ))}
                        </div>
                        {hasAnyCtaButton && (
                            <div
                                className={twMerge(
                                    'flex',
                                    tall && 'pt-4 pb-4',
                                    alignment === 'center' && 'justify-center',
                                )}
                            >
                                {showCallToAction && (
                                    <Button className="no-underline" {...callToAction}>
                                        {callToAction?.text}
                                    </Button>
                                )}
                                {showSecondaryCta && (
                                    <Button className="no-underline ml-2" {...secondaryCta}>
                                        {secondaryCta?.text}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchHero;
