import React, { ReactElement } from 'react';

import { Button, ButtonSize, ButtonVariant, ButtonColor } from '@videoblocks/storywind';

export interface CallToActionProps {
    color: ButtonColor;
    href: string;
    showCallToAction: boolean;
    size: ButtonSize;
    squared: boolean;
    text: string;
    variant: ButtonVariant;
    target?: string;
    rel?: string;
    onClick?: () => void;
}

const CallToAction = ({
    color,
    href,
    showCallToAction,
    size,
    squared,
    text,
    variant,
    target,
    rel,
    onClick = () => {
        return undefined;
    },
}: CallToActionProps): ReactElement => (
    <>
        {showCallToAction && text && (
            <div className="flex justify-center text-center">
                <Button
                    className={`${href ? 'text-black no-underline' : ''}`}
                    color={color}
                    href={href}
                    size={size}
                    squared={squared}
                    variant={variant}
                    target={target}
                    rel={rel}
                    onClick={onClick}
                >
                    {text}
                </Button>
            </div>
        )}
    </>
);

export const callToActionFields = [
    {
        name: 'text',
        type: 'string',
        default: '',
        required: true,
    },
    {
        name: 'href',
        type: 'string',
        default: '/',
        required: true,
    },
    {
        name: 'color',
        type: 'string',
        default: ButtonColor.Primary,
        enum: [ButtonColor.Primary, ButtonColor.Secondary, ButtonColor.Light],
    },
    {
        name: 'size',
        type: 'string',
        default: ButtonSize.Medium,
        enum: [ButtonSize.Small, ButtonSize.Medium, ButtonSize.Large],
    },
    {
        name: 'variant',
        type: 'string',
        default: ButtonVariant.Contained,
        enum: [ButtonVariant.Contained, ButtonVariant.Outlined],
    },
    {
        name: 'target',
        type: 'string',
        enum: ['_self', '_blank', '_parent', '_top'],
        helperText:
            'For explanation of options, see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target',
    },
    {
        name: 'rel',
        type: 'string',
        helperText:
            'For documentation on accepted values, see https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types for link types allowed in <a> elements',
    },
    {
        name: 'squared',
        type: 'boolean',
        default: false,
    },
];

export const callToActionInputs = [
    {
        name: 'showCallToAction',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'callToAction',
        type: 'object',
        defaultValue: {
            text: 'Explore',
            href: '/',
            color: 'primary',
            size: 'medium',
            variant: 'contained',
            target: '_self',
            rel: '',
            squared: false,
        },
        showIf: (options) => options.get('showCallToAction'),
        subFields: callToActionFields,
    },
];

export const secondaryCallToActionInputs = [
    {
        name: 'showSecondaryCta',
        type: 'boolean',
        defaultValue: false,
    },
    {
        name: 'secondaryCta',
        type: 'object',
        defaultValue: {
            text: 'Secondary CTA',
            href: '/',
            color: 'secondary',
            size: 'medium',
            variant: 'contained',
            target: '_self',
            rel: '',
            squared: false,
        },
        showIf: (options) => options.get('showSecondaryCta'),
        subFields: callToActionFields,
    },
];

export default CallToAction;
